import { Context } from "@/context";
import { dataDisclaimer, dataSocial } from "@/data/footer.data";
import DataLayer from "@/utils/DataLayer";
import { FC, useContext } from "react";
import styles from "./footer.module.scss";

const Footer: FC = () => {
  const { state } = useContext(Context);
  return (
    <footer className={styles.container}>
      <div className={styles.social}>
        {dataSocial.map((social) => {
          const tracking = () => {
            DataLayer.clickEvent({
              element: social.title,
              elementCategory: "icone",
              pageSection: "footer",
              pageSubsection: "social",
            });
          };
          return (
            <a
              key={social.id}
              target="_blank"
              href={social.url}
              title={social.title}
              data-before={social.icon}
              onClick={() => {
                tracking();
              }}
              onAuxClick={() => {
                tracking();
              }}
            >
              {social.title}
            </a>
          );
        })}
      </div>
      <div className={styles.disclaimer}>
        {dataDisclaimer.map((description) => {
          return state.layout.isDesktop
            ? description.description.desktop
            : description.description.mobile;
        })}

<legend className={styles.legalText}>*Fonte: FENABRAVE</legend>
      </div>
    </footer>
  );
};

export default Footer;
