import { gallery, galleryThumbs } from "@/assets/gallery";
import {
    ArrowButton,
    IconButton,
    SectionElement,
    VimeoEmbed
} from "@/components";
import { Magnifying } from "@/components/svg-components";
import { Context } from "@/context";
import { dataMenuLabels } from "@/data/menu.data";
import DataLayer from "@/utils/DataLayer";
import getRangeOfEntries from "@/utils/getRangeOfEntries";
import scssStyles from "@/utils/scssStyles";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import Image from "next/image";
import { FC, useCallback, useContext, useState } from "react";
import { useSwipeable } from "react-swipeable";
import SwiperCore, { A11y, Keyboard, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ExpandedGallery from "./expanded-gallery";
import styles from "./gallery-section.module.scss";

SwiperCore.use([A11y, Keyboard, Thumbs]);

const reference = dataMenuLabels().find((c) => c.slug === "galeria");

export const GalleryV1: FC = observer(() => {
  const { state, dispatch } = useContext(Context);
  const [controller, setController] = useState<SwiperCore>();
  const pageSubsection = "galeria";

  const findIndex = () => {
    return gallery.findIndex(
      (g) => g.id === state.layout.selectedGalleryImage?.id
    );
  };

  const updateIndex = useCallback(
    (n: number) => {
      dispatch({
        type: "SET_GALLERY_IMAGE",
        payload: { selectedGalleryImage: gallery[n] },
      });
      if (controller && typeof n === "number") {
        controller?.slideTo(n);
      }
    },
    [controller, dispatch]
  );

  const next = () => {
    const index = findIndex();
    const nIndex = index + 1 > gallery.length - 1 ? 0 : index + 1;
    updateIndex(nIndex);
  };
  const previous = () => {
    const index = findIndex();
    const nIndex = index - 1 < 0 ? gallery.length - 1 : index - 1;
    updateIndex(nIndex);
  };

  const swipeHandlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => {
      DataLayer.swipeEvent({
        element: "anterior",
        elementCategory: "imagem",
        pageSection: "conteudo",
        pageSubsection,
      });
      next();
    },
    onSwipedRight: () => {
      DataLayer.swipeEvent({
        element: "proximo",
        elementCategory: "imagem",
        pageSection: "conteudo",
        pageSubsection,
      });
      previous();
    },
  });

  const bulletProps = getRangeOfEntries(findIndex(), gallery);

  return (
    <>
      <SectionElement
        noPadding
        id="Gallery"
        className={styles.container}
        navReference={reference}
      >
        <div className={styles.title}>
          {state.layout.isDesktop ? (
            <h2>
              A sua <span>Fiat Strada</span> por todos os ângulos
            </h2>
          ) : (
            <h2>
              A sua <span>Fiat Strada</span>
              <br /> por todos os ângulos
            </h2>
          )}
        </div>
        <div className={styles.galleryContainer}>
          <div className={styles.aligner}>
            <div className={styles.mainImage}>
              {!state.layout.selectedGalleryImage?.video && (
                <IconButton
                  className={styles.expandIcon}
                  title="Expandir imagem"
                  icon={
                    <>
                      <Magnifying />
                    </>
                  }
                  handleClick={() => {
                    DataLayer.clickEvent({
                      element: `amplia-foto-${
                        gallery.findIndex(
                          (c) => c.id === state.layout.selectedGalleryImage.id
                        ) + 1
                      }`,
                      elementCategory: "icone",
                      pageSection: "conteudo",
                      pageSubsection,
                    });
                    dispatch({
                      type: "SET_GALLERY_EXPANDED",
                      payload: {
                        galleryExpanded: !state.layout.galleryExpanded,
                      },
                    });
                  }}
                />
              )}
              <AnimatePresence>
                {gallery.map((g) => {
                  const isMobile = !state.layout.isDesktop;
                  if (state.layout.selectedGalleryImage?.id !== g.id)
                    return null;
                  return (
                    <motion.div
                      key={`main-gallery-${g.id}`}
                      {...swipeHandlers}
                      initial={{
                        opacity: 0,
                        y: isMobile ? -100 : 0,
                        x: !isMobile ? 100 : 0,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        x: 0,
                        scale: 1,
                      }}
                      exit={{
                        opacity: 0,
                        y: isMobile ? 100 : 0,
                        x: !isMobile ? 100 : 0,
                      }}
                      transition={{
                        duration: 0.2,
                      }}
                    >
                      {!!g.video && !!g.video.desktop ? (
                        <VimeoEmbed
                          id={g.video.desktop}
                          className={styles.videoHolder}
                          title={g.title}
                        />
                      ) : (
                        <Image
                            alt={g.alt}
                            title={g.title}
                            src={g.asset.fullPath3x}
                            layout="fill"
                            objectPosition="center"
                            objectFit={state.layout.isDesktop ? "cover" : "fill"}
                        />
                      )}
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </div>
            {state.layout.isDesktop ? (
              <div className={styles.thumbsDesktop}>
                {galleryThumbs.map((p, index) => (
                  <button
                    key={`galleryThumb-${p.id}`}
                    onClick={() => {
                      DataLayer.clickEvent({
                        element: `foto-${index + 1}`,
                        elementCategory: "icone",
                        pageSection: "conteudo",
                        pageSubsection,
                      });
                      dispatch({
                        type: "SET_GALLERY_IMAGE",
                        payload: { selectedGalleryImage: p },
                      });
                    }}
                    className={scssStyles([
                      state.layout.selectedGalleryImage?.id === p.id
                        ? styles.active
                        : "",
                    ])}
                  >
                   <Image src={p.thumb} alt={p.alt} title={p.title} layout="responsive" objectFit="contain" width={224} height={152}/>
                  </button>
                ))}
              </div>
            ) : (
              <>
                <div className={styles.thumbs}>
                  <Swiper
                    observer
                    observeParents
                    parallax
                    spaceBetween={10}
                    slidesPerView={2.5}
                    onSwiper={(e) => setController(e)}
                    controller={
                      controller ? { control: controller } : undefined
                    }
                    className={styles.swiperMobile}
                  >
                    {galleryThumbs.map((p, index) => (
                      <SwiperSlide
                        key={`gallery-thumb-${p.id}`}
                        className={styles.swiperSlide}
                      >
                        <button
                          onClick={() => {
                            DataLayer.clickEvent({
                              element: `foto-${index + 1}`,
                              elementCategory: "imagem",
                              pageSection: "conteudo",
                              pageSubsection,
                            });
                            updateIndex(index);
                          }}
                          className={
                            state.layout.selectedGalleryImage.id === p.id
                              ? styles.active
                              : ""
                          }
                        >
                            <div className={styles.thumbContainer}>
                                <Image    
                                    src={p.thumb}
                                    alt={p.alt}
                                    title={p.title}
                                    layout="responsive"
                                    objectFit="contain"
                                    width={128}
                                    height={87}
                                />
                            </div>
                        </button>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <div className={styles.controls}>
                  <ArrowButton
                    title={"Anterior"}
                    previous
                    handleClick={() => {
                      previous();
                    }}
                  />
                  <div className={styles.bullets}>
                    {!!bulletProps.before &&
                      Array.from(Array(bulletProps.before).keys())
                        .slice(0, 2)
                        .map((_, i) => (
                          <button
                            key={`smaller-bullet-gallery-before-${i}`}
                            className={scssStyles([
                              styles.bullet,
                              i == 1 || bulletProps.before === 1
                                ? "mediumBullet"
                                : "smallerBullet",
                            ])}
                            onClick={() => {
                              DataLayer.clickEvent({
                                element: `foto-${findIndex() - 2}`,
                                elementCategory: "icone",
                                pageSection: "conteudo",
                                pageSubsection,
                              });
                              updateIndex(findIndex() - 2);
                            }}
                          >
                            <span />
                          </button>
                        ))}
                    {gallery.map((c, index) => {
                      if (bulletProps.range.indexOf(index) === -1) return null;
                      return (
                        <button
                          key={`bullet-${c.id}`}
                          title={c.title}
                          className={scssStyles([
                            styles.bullet,
                            state.layout.selectedGalleryImage?.id === c.id
                              ? styles.active
                              : "",
                          ])}
                          onClick={() => {
                            DataLayer.clickEvent({
                              element: `foto-${index + 1}`,
                              elementCategory: "icone",
                              pageSection: "conteudo",
                              pageSubsection,
                            });
                            dispatch({
                              type: "SET_GALLERY_IMAGE",
                              payload: { selectedGalleryImage: c },
                            });
                          }}
                        >
                          <span />
                        </button>
                      );
                    })}
                    {!!bulletProps.after &&
                      Array.from(Array(bulletProps.after).keys())
                        .slice(0, 2)
                        .map((_, i) => (
                          <button
                            key={`smaller-bullet-gallery-after-${i}`}
                            className={scssStyles([
                              styles.bullet,
                              i == 0 || bulletProps.after === 1
                                ? "mediumBullet"
                                : "smallerBullet",
                            ])}
                            onClick={() => {
                              DataLayer.clickEvent({
                                element: `foto-${findIndex() + 2}`,
                                elementCategory: "icone",
                                pageSection: "conteudo",
                                pageSubsection,
                              });
                              updateIndex(findIndex() + 2);
                            }}
                          >
                            <span />
                          </button>
                        ))}
                  </div>
                  <ArrowButton
                    title={"Próximo"}
                    handleClick={() => {
                      DataLayer.clickEvent({
                        element: "proximo",
                        elementCategory: "icone",
                        pageSection: "conteudo",
                        pageSubsection,
                      });
                      next();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </SectionElement>

      <AnimatePresence>
        {state.layout.galleryExpanded && <ExpandedGallery />}
      </AnimatePresence>
    </>
  );
});
