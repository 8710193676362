import { CTAButton, SectionElement } from "@/components";
import { Context } from "@/context";
import { dataMenuLabels } from "@/data/menu.data";
import { dataProfile } from "@/data/profile.data";
import DataLayer from "@/utils/DataLayer";
import { FC, useContext } from "react";
import styles from "./profile-section.module.scss";

const ProfileSection: FC = () => {
  const reference = dataMenuLabels().find((c) => c.slug === "rural-e-empresas");
  const { state } = useContext(Context);

  return (
    <SectionElement
      noPadding
      id="profileSection"
      className={styles.profileSection}
      navReference={reference}
    >
      <div className={styles.title}>
        <h2>
          Está procurando o <span>parceiro perfeito</span> para o trabalho?
        </h2>
      </div>
      <div className={styles.container}>
        <div className={styles.gallery}>
          {dataProfile.map((item) => {
            return (
              <div className={styles.box} key={item.title}>
                <div className={styles.content}>
                  <div className={styles.textHolder}>
                    <h3>{item.title}</h3>
                    <p className={styles.text}>{item.text}</p>
                    <CTAButton
                      href={item.url}
                      className={styles.ctaButton}
                      text={item.buttonText}
                      title={item.buttonText}
                      handleClick={() =>
                        DataLayer.clickEvent({
                          element: item.buttonText,
                          elementCategory: "cta",
                          pageSection: "conteudo",
                          pageSubsection: item.title,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </SectionElement>
  );
};

export default ProfileSection;
