import { GalleryAsset, gallery as galleryAll } from '@/assets/gallery';
import { ArrowButton, Conditional } from '@/components';
import { Close } from '@/components/svg-components';
import { Context } from '@/context';
import DataLayer from '@/utils/DataLayer';
import getClientXFromDifferentEvents from '@/utils/getClientXFromDifferentEvents';
import getRangeOfEntries from '@/utils/getRangeOfEntries';
import scssStyles from '@/utils/scssStyles';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from '../gallery-section.module.scss';
import Image from 'next/image';

const VisibleImage: FC<{ g: GalleryAsset }> = observer(({ g }) => {
  return (
    <Image src={g.asset.fullPath2x} alt={g.title} width={2499} height={1608} />
  );
});

const gallery = galleryAll.filter((c) => !c.video);

const ExpandedGallery: FC = observer(() => {
  const { state, dispatch } = useContext(Context);
  const [, update] = useState(false);
  const [touchLocation, setTouchLocation] = useState<PointerEvent['clientX']>();
  const pageSubsection = 'galeria';

  const findIndex = useCallback(() => {
    return gallery.findIndex(
      (g) => g.id === state.layout.selectedGalleryImage.id
    );
  }, [state.layout.selectedGalleryImage.id]);

  const updateIndex = useCallback(
    (n: number) => {
      dispatch({
        type: 'SET_GALLERY_IMAGE',
        payload: { selectedGalleryImage: gallery[n] },
      });
      state.layout.expandedSwiperController?.slideTo(n);
    },
    [dispatch, state.layout.expandedSwiperController]
  );

  const next = () => {
    const index = findIndex();
    const nIndex = index + 1 > gallery.length - 1 ? 0 : index + 1;
    updateIndex(nIndex);
  };
  const previous = () => {
    const index = findIndex();
    const nIndex = index - 1 < 0 ? gallery.length - 1 : index - 1;
    updateIndex(nIndex);
  };

  const swipeHandlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => next(),
    onSwipedRight: () => previous(),
  });

  const ArrowRight = (
    <ArrowButton
      title={'Próximo'}
      className={styles.arrowRight}
      handleClick={() => {
        DataLayer.swipeEvent({
          element: 'proximo',
          elementCategory: 'imagem',
          pageSection: 'conteudo',
          pageSubsection,
        });
        next();
      }}
    />
  );

  const ArrowLeft = (
    <ArrowButton
      title={'Anterior'}
      previous
      className={styles.arrowLeft}
      handleClick={() => {
        DataLayer.swipeEvent({
          element: 'anterior',
          elementCategory: 'imagem',
          pageSection: 'conteudo',
          pageSubsection,
        });
        previous();
      }}
    />
  );

  useEffect(() => {
    update((v) => !v);
  }, []);

  useEffect(() => {
    if (
      state.layout.galleryExpanded &&
      state.layout.expandedSwiperController?.destroyed !== true
    ) {
      state.layout.expandedSwiperController?.slideTo(findIndex());
    }
  }, [
    findIndex,
    state.layout.expandedSwiperController,
    state.layout.galleryExpanded,
  ]);

  useEffect(() => {
    const escapeClose = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        dispatch({
          type: 'SET_GALLERY_EXPANDED',
          payload: { galleryExpanded: false },
        });
      }
    };

    document.body?.addEventListener('keydown', (e) => escapeClose(e));

    return () => {
      document.body?.removeEventListener('keydown', (e) => escapeClose(e));
    };
  }, [dispatch]);

  const bulletProps = getRangeOfEntries(findIndex(), gallery);

  return (
    <motion.div
      className={styles.expandedGallery}
      initial={{
        opacity: 0.2,
        y: 100,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{
        opacity: 0.2,
        y: 100,
      }}
      transition={{
        duration: 0.3,
      }}
    >
      <Swiper
        observeParents
        observer
        parallax
        spaceBetween={50}
        slidesPerView={1}
        onSwiper={(e) =>
          dispatch({
            type: 'SET_EXPANDED_CONTROLLER',
            payload: { expandedSwiperController: e },
          })
        }
        controller={
          state.layout.expandedSwiperController
            ? { control: state.layout.expandedSwiperController }
            : undefined
        }
        onSlideChange={(e) => {
          const index = e.activeIndex;
          dispatch({
            type: 'SET_GALLERY_IMAGE',
            payload: { selectedGalleryImage: gallery[index] },
          });
        }}
        onTouchStart={(_, event) => {
          const clientX = getClientXFromDifferentEvents(event);
          setTouchLocation(clientX);
        }}
        onTouchEnd={(_, event) => {
          const clientX = getClientXFromDifferentEvents(event);
          DataLayer.swipeEvent({
            element: (touchLocation ?? 0) > clientX ? 'proximo' : 'anterior',
            elementCategory: 'imagem',
            pageSection: 'conteudo',
            pageSubsection,
          });
        }}
      >
        {gallery.map((g) => (
          <SwiperSlide
            key={`expanded-gallery-${g.id}`}
            className={styles.expandedSlide}
          >
            <VisibleImage g={g} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Conditional notOn="desktop">
        <div className={styles.controls} {...swipeHandlers}>
          {ArrowLeft}
          <div className={styles.bullets}>
            {!!bulletProps.before &&
              Array.from(Array(bulletProps.before).keys())
                .slice(0, 2)
                .map((_, i) => (
                  <button
                    key={`smaller-bullet-galleryExpanded-before-${i}`}
                    className={scssStyles([
                      styles.bullet,
                      i == 1 || bulletProps.before === 1
                        ? 'mediumBullet'
                        : 'smallerBullet',
                    ])}
                    onClick={() => {
                      DataLayer.clickEvent({
                        element: `foto-${findIndex() - 2}`,
                        elementCategory: 'icone',
                        pageSection: 'conteudo',
                        pageSubsection,
                      });
                      updateIndex(findIndex() - 2);
                    }}
                  >
                    <span />
                  </button>
                ))}
            {gallery.map((c, index) => {
              if (bulletProps.range.indexOf(index) === -1) return null;
              return (
                <button
                  title={c.title}
                  key={`bullet-${c.id}`}
                  className={scssStyles([
                    styles.bullet,
                    state.layout.selectedGalleryImage.id === c.id
                      ? styles.active
                      : '',
                  ])}
                  onClick={() => {
                    DataLayer.clickEvent({
                      element: `foto-${index + 1}`,
                      elementCategory: 'imagem',
                      pageSection: 'conteudo',
                      pageSubsection,
                    });
                    dispatch({
                      type: 'SET_GALLERY_IMAGE',
                      payload: { selectedGalleryImage: c },
                    });
                    state.layout.expandedSwiperController?.slideTo(
                      gallery.findIndex((x) => x.id === c.id)
                    );
                  }}
                >
                  <span />
                </button>
              );
            })}
            {!!bulletProps.after &&
              Array.from(Array(bulletProps.after).keys())
                .slice(0, 2)
                .map((_, i) => (
                  <button
                    key={`smaller-bullet-galleryExpanded-after-${i}`}
                    className={scssStyles([
                      styles.bullet,
                      i == 0 || bulletProps.after === 1
                        ? 'mediumBullet'
                        : 'smallerBullet',
                    ])}
                    onClick={() => {
                      DataLayer.clickEvent({
                        element: `foto-${findIndex() + 2}`,
                        elementCategory: 'icone',
                        pageSection: 'conteudo',
                        pageSubsection,
                      });
                      updateIndex(findIndex() + 2);
                    }}
                  >
                    <span />
                  </button>
                ))}
          </div>
          {ArrowRight}
        </div>
      </Conditional>
      <button
        title="Fechar"
        className={styles.closeButton}
        onClick={() => {
          DataLayer.clickEvent({
            element: 'fechar',
            elementCategory: 'icone',
            pageSection: 'conteudo',
            pageSubsection,
          });
          dispatch({
            type: 'SET_GALLERY_EXPANDED',
            payload: { galleryExpanded: !state.layout.galleryExpanded },
          });
        }}
      >
        <Close />
      </button>
      <Conditional notOn="mobile">
        <div className={styles.controlsDesktop}>
          {ArrowLeft}
          {ArrowRight}
        </div>
      </Conditional>
    </motion.div>
  );
});

export default ExpandedGallery;
