import {
  IncludePrefixResponsiveSizes,
  includeResponsive,
} from "@utils/imagePrefixes";

export interface IHero {
  id: string;
  title: string | JSX.Element;
  subtitle?: string;
  titleStories: string | JSX.Element;
  description: string | JSX.Element;
  descriptionStories: string | JSX.Element;
  cardTitle: string;
  cardSrc: IncludePrefixResponsiveSizes;
  backgrounds: IncludePrefixResponsiveSizes[];
  titleTag: string;
  altTag: string;
}

const STORIES_PREFIX = "Stories/";
const HERO_DESKTOP_PREFIX = "HeroDesktop/";
const THUMB_PREFIX = `${HERO_DESKTOP_PREFIX}thumbs/`;
const BACKGROUNDS_PREFIX = `${HERO_DESKTOP_PREFIX}backgrounds/`;

export const heroFirstBackground = includeResponsive(
  `${BACKGROUNDS_PREFIX}fiat-strada-2024`,
  "webp"
);
export const heroFirstAltTag = "Nova Fiat Strada";
export const heroFirstTitleTag = "Nova Fiat Strada";
export const storiesFirstBackground = includeResponsive(
  `${STORIES_PREFIX}fiat-strada-2024`,
  "webp"
);
export const storiesFirstAltTag = "Nova Fiat Strada";
export const storiesFirstTitleTag = "Nova Fiat Strada";

export const dataHero: IHero[] = [
  {
    id: "1",
    title: "Nova versão Ultra",
    titleStories: <>Nova versão Ultra</>,
    description: (
      <>
        A nova versão Ultra chega com seu visual único, robusto e urbano para enfrentar seus desafios diários.
      </>
    ),
    descriptionStories: (
      <>
        A nova versão Ultra chega com seu visual único, robusto e urbano para enfrentar seus desafios diários.
      </>
    ),
    altTag: "Nova versão Ultra",
    titleTag: "Nova versão Ultra",
    cardTitle: "Nova versão Ultra",
    cardSrc: includeResponsive(
      `${THUMB_PREFIX}fiat-strada-nova-versao-ultra-thumb`,
      "webp"
    ),
    backgrounds: [
      includeResponsive(
        `${BACKGROUNDS_PREFIX}fiat-strada-nova-versao-ultra`,
        "webp"
      ),
      includeResponsive(
        `${STORIES_PREFIX}fiat-strada-nova-versao-ultra`,
        "webp"
      ),
    ],
  },
  {
    id: "2",
    title: "Novo design",
    titleStories: <>Novo design</>,
    description: (
      <>
        A união perfeita entre versatilidade e design. Aprecie detalhe como volante com regulagem de altura, bancos em tecido com Fiat Flag e mais.
      </>
    ),
    descriptionStories: (
      <>
        A união perfeita entre versatilidade e design. Aprecie detalhe como volante com regulagem de altura, bancos em tecido com Fiat Flag e mais.
      </>
    ),
    altTag: "Novo design",
    titleTag: "Novo design",
    cardTitle: "Novo design",
    cardSrc: includeResponsive(
      `${THUMB_PREFIX}fiat-strada-novo-design-unico-thumb`,
      "webp"
    ),
    backgrounds: [
      includeResponsive(
        `${BACKGROUNDS_PREFIX}fiat-strada-novo-design-unico`,
        "webp"
      ),
      includeResponsive(
        `${STORIES_PREFIX}fiat-strada-novo-design-unico`,
        "webp"
      ),
    ],
  },
  {
    id: "3",
    title: "Motor Turbo 200 Flex",
    titleStories: <>Motor Turbo 200 Flex</>,
    description: (
      <>
        A primeira picape compacta de turbo do mundo. A Strada que você já conhece ficou ainda melhor com novo Motor Turbo Flex T200 com economia como você nunca viu.
      </>
    ),
    descriptionStories: (
      <>
        A primeira picape compacta de turbo do mundo. A Strada que você já conhece ficou ainda melhor com novo Motor Turbo Flex T200 com economia como você nunca viu.
      </>
    ),
    altTag: "Motor Turbo 200 Flex",
    titleTag: "Motor Turbo 200 Flex",
    cardTitle: "Motor Turbo 200 Flex",
    cardSrc: includeResponsive(
      `${THUMB_PREFIX}fiat-strada-novo-motor-turbo-200-thumb`,
      "webp"
    ),
    backgrounds: [
      includeResponsive(
        `${BACKGROUNDS_PREFIX}fiat-strada-novo-motor-turbo-200`,
        "webp"
      ),
      includeResponsive(
        `${STORIES_PREFIX}fiat-strada-novo-motor-turbo-200`,
        "webp"
      ),
    ],
  },
  {
    id: "4",
    title: "A/C Digital Automático",
    titleStories: <>A/C Digital Automático</>,
    description: (
      <>
        Mais conforto e tecnologia em qualquer momento. Controle o clima com botões de fácil acesso com ar-condicionado digital da sua Fiat Strada.
      </>
    ),
    descriptionStories: (
      <>
        Mais conforto e tecnologia em qualquer momento. Controle o clima com botões de fácil acesso com ar-condicionado digital da sua Fiat Strada.
      </>
    ),
    altTag: "A/C Digital Automático",
    titleTag: "A/C Digital Automático",
    cardTitle: "A/C Digital Automático",
    cardSrc: includeResponsive(
      `${THUMB_PREFIX}fiat-strada-ar-condicionado-digital-thumb`,
      "webp"
    ),
    backgrounds: [
      includeResponsive(
        `${BACKGROUNDS_PREFIX}fiat-strada-ar-condicionado-digital`,
        "webp"
      ),
      includeResponsive(
        `${STORIES_PREFIX}fiat-strada-ar-condicionado-digital`,
        "webp"
      ),
    ],
  },
  {
    id: "5",
    title: "Carregamento sem fio",
    titleStories: <>Carregamento sem fio</>,
    description: (
      <>
        Praticidade em qualquer momento. Carregue seu celular sem o uso de fios através da tecnologia Wireless Charger e mantenha-se sempre conectado.
      </>
    ),
    descriptionStories: (
      <>
        Praticidade em qualquer momento. Carregue seu celular sem o uso de fios através da tecnologia Wireless Charger e mantenha-se sempre conectado.
      </>
    ),
    altTag: "Carregamento sem fio",
    titleTag: "Carregamento sem fio",
    cardTitle: "Carregamento sem fio",
    cardSrc: includeResponsive(
      `${THUMB_PREFIX}fiat-strada-carregamento-sem-fio-thumb`,
      "webp"
    ),
    backgrounds: [
      includeResponsive(
        `${BACKGROUNDS_PREFIX}fiat-strada-carregamento-sem-fio`,
        "webp"
      ),
      includeResponsive(
        `${STORIES_PREFIX}fiat-strada-carregamento-sem-fio`,
        "webp"
      ),
    ],
  },
];
