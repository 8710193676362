import { Context } from "@/context";
import { PRODUCT_DETAILS_PREFIX } from "@/data/menu.data";
import productDetails, { ProductTab } from "@/data/product-details.data";
import DataLayer from "@/utils/DataLayer";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect } from "react";
import styles from "./product-details-tabs.module.scss";

interface Tabs {
  flexDirection?: "row" | "column";
}

const Tab: FC<{ tab: ProductTab }> = observer(({ tab }) => {
  const { state, dispatch } = useContext(Context);

  const pageSubsection = "tudo-sobre";

  const handleSelectedDetailTab = (tab: ProductTab): void => {
    dispatch({ type: "SET_CURRENTLY_VISIBLE_NAV", payload: { tab: tab } });

    dispatch({
      type: "SET_DETAIL_TAB",
      payload: {
        selectedDetailTab: tab,
      },
    });
  };

  useEffect(() => {
    if (state.layout.selectedDetailTab === "") {
      dispatch({
        type: "SET_DETAIL_TAB",
        payload: { selectedDetailTab: productDetails[0] },
      });

      dispatch({
        type: "SWIPE_TO_TAB",
        payload: { tab: productDetails[0] },
      });
    }
  }, [dispatch, state.layout.selectedDetailTab]);

  return (
    <a
      className={scssStyles([
        styles.tab,
        state.layout.selectedDetailTab === tab.id ? styles.active : "",
      ])}
      href={`/strada/${PRODUCT_DETAILS_PREFIX}/${tab.slug}`}
      onClick={(e) => {
        DataLayer.clickEvent({
          element: tab.title,
          elementCategory: "botao",
          pageSection: "conteudo",
          pageSubsection,
        });

        dispatch({
          type: "SET_DETAIL_TAB",
          payload: { selectedDetailTab: tab },
        });

        dispatch({
          type: "SWIPE_TO_TAB",
          payload: { tab },
        });

        handleSelectedDetailTab(tab);

        e.preventDefault();
      }}
    >
      <div className={styles.content}>
        <h3>{tab.title}</h3>
      </div>
    </a>
  );
});

const Tabs: FC<Tabs> = ({ flexDirection }) => {
  return (
    <div
      className={styles.container}
      style={{ flexDirection: flexDirection ? flexDirection : "row" }}
    >
      {productDetails.map((tab) => (
        <Tab key={`tab--${tab.id}`} tab={tab} />
      ))}
    </div>
  );
};

export default Tabs;
