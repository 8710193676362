import { profiles } from "@/assets";

export interface IDataProfile {
  imageMobile: string;
  imageDesktop: string;
  title: string;
  text: string;
  url: string;
  buttonText: string;
}

export const dataProfile: IDataProfile[] = [
  {
    imageMobile: profiles.image1,
    imageDesktop: profiles.image3,
    title: "Produtor Rural",
    text: "A Fiat Strada tem a força e a capacidade de carga que todo produtor rural precisa. Confira as condições exclusivas para você que pega pesado no campo.",
    url: "https://www.fiat.com.br/vendas-diretas/produtores-rurais.html/",
    buttonText: "Saiba mais",
  },
  {
    imageMobile: profiles.image2,
    imageDesktop: profiles.image4,
    title: "Empresas",
    text: "O maior volume de carga da categoria foi feito para quem carrega o negócio sobre rodas. Confira as condições exclusivas para a sua empresa.",
    url: "https://www.fiat.com.br/vendas-diretas/cnpj-e-microempresarios.html/",
    buttonText: "Saiba mais",
  },
];
