import { ArrowButton, CTAButton, VimeoEmbed } from '@/components';
import { Context } from '@/context';
import productDetails, {
  ProductTab,
  ProductTabSlide,
  allSlides,
} from '@/data/product-details.data';
import DataLayer from '@/utils/DataLayer';
import getClientXFromDifferentEvents from '@/utils/getClientXFromDifferentEvents';
import scssStyles from '@/utils/scssStyles';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useState } from 'react';
import SwiperCore, { A11y, Keyboard, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Tabs from '../product-details-tabs-component';
import styles from './swiper-product-details-desktop.module.scss';
import Image from 'next/image';

SwiperCore.use([A11y, Keyboard, Thumbs]);

interface SlideProps {
  slide: ProductTabSlide;
  tab: ProductTab;
  productDetailsVersion?: 1 | 2;
}

interface SwiperProps {
  productDetailsVersion?: 1 | 2;
  setReference: (tab: ProductTab) => void;
}

const pageSection = 'tudo-sobre';

const Slide: FC<SlideProps> = observer(({ slide, productDetailsVersion }) => {
  return (
    <div className={styles.slideContainer}>
      {slide.vimeoId?.desktop ? (
        <div className={styles.videoWrapper}>
          <VimeoEmbed id={slide.vimeoId.desktop} title={slide.title} />
        </div>
      ) : productDetailsVersion === 2 ? null : (
        <div className={styles.slideImageContainer}>
          <Image
            src={slide.image.fullPath2x}
            alt={slide.title}
            title={slide.title}
            width={2196}
            height={1413}
          />
        </div>
      )}
      <div className={styles.slideDetails}>
        <h4>{slide.parentTitle}</h4>
        <h3>{slide.title}</h3>
        <div className={styles.slideDescription}>
          {slide.descriptionDesktop}
          {!!slide.fileUrl && (
            <>
              <CTAButton
                key={`menu-cta-${slide.id}`}
                href={slide.fileUrl}
                className={styles.ctaButton}
                text={slide.labelBtn ?? ''}
                width={'25%'}
                title={slide.title}
                backgroundColor={'#fff'}
                color={'#000'}
                excludeArrow={slide.breakTitle}
                //download={item.download}
                //iconCode={item.iconCode}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
});

const SwipersDesktop: FC<SwiperProps> = observer(
  ({ productDetailsVersion, setReference }) => {
    const { state, dispatch } = useContext(Context);
    const [, rerender] = useState(false);
    const [touchLocation, setTouchLocation] =
      useState<PointerEvent['clientX']>();
    const nextSlide = !state.layout.productDetailsSwiperController
      ? undefined
      : allSlides[state.layout.productDetailsSwiperController.activeIndex + 1];
    const previousSlide = !state.layout.productDetailsSwiperController
      ? undefined
      : allSlides[state.layout.productDetailsSwiperController.activeIndex - 1];

    const handleSwiperChange = async (tab: ProductTab) => {
      await dispatch({
        type: 'SET_CURRENTLY_VISIBLE_NAV',
        payload: {
          tab: tab,
        },
      });

      await dispatch({
        type: 'SET_DETAIL_TAB',
        payload: {
          selectedDetailTab: tab,
        },
      });
    };

    return (
      <div className={styles.holder}>
        <div className={styles.tab}>
          <Tabs />
        </div>
        <div className={styles.content}>
          <div className={styles.container}>
            <Swiper
              observer
              observeParents
              parallax
              onSwiper={(e) =>
                dispatch({
                  type: 'SET_PRODUCT_DETAILS_CONTROLLER',
                  payload: { productDetailsSwiperController: e },
                })
              }
              controller={
                state.layout.productDetailsSwiperController
                  ? { control: state.layout.productDetailsSwiperController }
                  : undefined
              }
              spaceBetween={100}
              onSlideChange={(swiper) => {
                const index = swiper.activeIndex;
                const tab = productDetails.find(
                  (p) => p.id === allSlides[index].tabId
                );
                if (tab) {
                  handleSwiperChange(tab);
                  setReference(tab);
                }
                rerender((r) => !r);
              }}
              onTouchStart={(_, event) => {
                const clientX = getClientXFromDifferentEvents(event);
                setTouchLocation(clientX);
              }}
              onTouchEnd={(_, event) => {
                const clientX = getClientXFromDifferentEvents(event);
                DataLayer.swipeEvent({
                  element:
                    (touchLocation ?? 0) > clientX ? 'proximo' : 'anterior',
                  elementCategory: 'imagem',
                  pageSection,
                  pageSubsection:
                    ((touchLocation ?? 0) > clientX ? nextSlide : previousSlide)
                      ?.parentSlug ??
                    productDetails.find(
                      (c) => c.id === state.layout.selectedDetailTab
                    )?.slug ??
                    '',
                });
              }}
            >
              {productDetails.map((tab) =>
                tab.children.map((slide) => (
                  <SwiperSlide key={`detail-${tab.id}-${slide.id}`}>
                    <Slide
                      tab={tab}
                      slide={{ ...slide, tabId: tab.id }}
                      productDetailsVersion={productDetailsVersion}
                    />
                  </SwiperSlide>
                ))
              )}
            </Swiper>
            <div className={styles.controls}>
              {state.layout.pageX >= 1200 && (
                <div className={styles.bullets}>
                  {allSlides
                    .filter((t) => t.tabId === state.layout.currentTab?.id)
                    .map((c, index) => {
                      return (
                        <button
                          key={`bullet-sw-${c.id}`}
                          title={c.title}
                          className={scssStyles([
                            styles.bullet,
                            state.layout.productDetailsSwiperController
                              ?.activeIndex === c.index
                              ? styles.active
                              : '',
                          ])}
                          onClick={() => {
                            DataLayer.clickEvent({
                              element: c.slug,
                              elementCategory: 'icone',
                              pageSection: 'conteudo',
                              pageSubsection: c.tabId ?? '',
                            });
                            if (
                              c.index &&
                              state.layout.productDetailsSwiperController
                            ) {
                              state.layout.productDetailsSwiperController?.slideTo(
                                c.index
                              );
                            }
                          }}
                        >
                          <span />
                        </button>
                      );
                    })}
                </div>
              )}
              <div className={styles.arrows}>
                <ArrowButton
                  previous
                  light
                  disabled={
                    (state.layout.productDetailsSwiperController?.activeIndex ??
                      0) -
                      1 <
                    0
                  }
                  handleClick={() => {
                    const pageSubsection: any = state.layout.selectedDetailTab;
                    DataLayer.clickEvent({
                      element: 'anterior',
                      elementCategory: 'icone',
                      pageSection,
                      pageSubsection,
                    });
                    state.layout.productDetailsSwiperController?.slidePrev();
                  }}
                />
                <ArrowButton
                  light
                  disabled={
                    (state.layout.productDetailsSwiperController?.activeIndex ??
                      allSlides.length) >=
                    allSlides.length - 1
                  }
                  handleClick={() => {
                    const pageSubsection: any = state.layout.selectedDetailTab;
                    DataLayer.clickEvent({
                      element: 'proximo',
                      elementCategory: 'icone',
                      pageSection,
                      pageSubsection,
                    });
                    state.layout.productDetailsSwiperController?.slideNext();
                  }}
                />
                {!!nextSlide?.title && (
                  <div className={styles.nextTab}>
                    <strong>Próximo</strong>
                    <p>{nextSlide?.title}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default SwipersDesktop;
