import { SectionElement } from "@/components";
import { Context } from "@/context";
import { PRODUCT_DETAILS_REF } from "@/data/menu.data";
import {
  allSlides,
  getProductDetails,
  productDetails,
} from "@/data/product-details.data";
import { FC, useContext } from "react";
import styles from "./product-details-section.module.scss";
import SwipersDesktop from "./product-details-swiper-component/swiper-product-details-desktop.component";
import SwipersMobile from "./product-details-swiper-component/swiper-product-details-mobile.component";

export const ProductDetails: FC = () => {
  const { state, dispatch } = useContext(Context);

  const setReference = (tab?: any) => {
    let reference;
    let suffix;
    const index = !state.layout.isDesktop
      ? state.layout.productDetailsMobileSwiperController[
          state.layout.selectedDetailTab ?? 0
        ]?.activeIndex
      : state.layout.productDetailsSwiperController?.activeIndex;

    if (tab) {
      reference = getProductDetails(productDetails).find(
        (p) => p.id === `data-${tab?.id}`
      );
      if (!state.layout.isDesktop) {
        suffix = tab.children[index ?? 0].slug;
      } else {
        suffix = allSlides[index ?? 0].slug;
      }
    } else {
      suffix = allSlides[index ?? 0].slug;
      reference = getProductDetails(productDetails).find(
        (p) => p.id === `data-${state.layout.currentTab?.id}`
      );
    }

    if (reference) {
      if (state.layout.selectedDetailTab) {
        dispatch({
          type: "SET_CURRENTLY_VISIBLE_NAV",
          payload: { view: reference, suffix: suffix },
        });
      }
    }
  };

  return (
    <SectionElement
      id="ProductDetails"
      navReference={PRODUCT_DETAILS_REF}
      className={styles.container}
    >
      <div className={styles.title}>
        <h2>
          Tudo sobre a <span>Fiat Strada</span>
        </h2>
      </div>
      {state.layout.isDesktop ? (
        <SwipersDesktop setReference={setReference} />
      ) : (
        <SwipersMobile setReference={setReference} />
      )}
    </SectionElement>
  );
};
