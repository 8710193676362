import { ui } from '@/assets';
import { ArrowButton, ChevronRight } from '@/components';
import { Context } from '@/context';
import productDetails, {
  ProductTab,
  ProductTabSlide,
} from '@/data/product-details.data';
import DataLayer from '@/utils/DataLayer';
import getClientXFromDifferentEvents from '@/utils/getClientXFromDifferentEvents';
import getRangeOfEntries from '@/utils/getRangeOfEntries';
import scssStyles from '@/utils/scssStyles';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import React, { FC, useContext, useState } from 'react';
import SwiperCore, { A11y, Keyboard, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './swiper-product-details-mobile.module.scss';

SwiperCore.use([A11y, Keyboard, Thumbs]);

const pageSubsection = 'tudo-sobre-strada';

interface SlideProps {
  slide: ProductTabSlide;
}

interface ISetReference {
  setReference: (tab?: ProductTab) => void;
}

const Slide: FC<SlideProps> = observer(({ slide }) => {
  return (
    <div className={styles.slideContainer}>
      <div className={styles.slideImage}>
        <Image
          alt={slide.title}
          title={slide.title}
          src={slide.image.fullPath3x}
          layout="fill"
          objectFit="contain"
          objectPosition="top"
        />
      </div>
      <div className={styles.slideDetails}>
        <h4>{slide.title}</h4>
        <div className={styles.slideDescription}>
          {slide.descriptionMobile}
          {!!slide.fileUrl && (
            <div className={styles.ctas}>
              <a href={slide.fileUrl} className={styles.buy} target={'_blank'}>
                Saiba Mais
                <ChevronRight />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

interface TabProps extends ISetReference {
  tab: ProductTab;
  rerender: React.Dispatch<React.SetStateAction<boolean>>;
}

const Tab: FC<TabProps> = ({ tab, rerender, setReference }) => {
  const [touchLocation, setTouchLocation] = useState<PointerEvent['clientX']>();
  const { state, dispatch } = useContext(Context);
  const bulletProps = getRangeOfEntries(
    state.layout.productDetailsMobileSwiperController[tab.id]?.activeIndex,
    tab.children
  );
  const activeIndex =
    state.layout.productDetailsMobileSwiperController[tab.id]?.activeIndex ?? 0;
  const getMaxHeight = (id: string) => {
    if (state.layout.selectedDetailTab === id) {
      return 700;
    }
    return 0;
  };

  const handleSelectedDetailTab = (tab: null | ProductTab): void => {
    if (tab) {
      dispatch({ type: 'SET_CURRENTLY_VISIBLE_NAV', payload: { tab: tab } });
    }

    dispatch({
      type: 'SET_DETAIL_TAB',
      payload: {
        isMobile: true,
        selectedDetailTab: tab,
      },
    });
  };

  const handleSwiper = (e: SwiperCore | null, tabId: string): void => {
    dispatch({
      type: 'SET_PRODUCT_DETAILS_MOBILE_SWIPER_CONTROLLER',
      payload: {
        productDetailsMobileSwiperController: e,
        tabId,
      },
    });
  };

  return (
    <>
      <button
        className={scssStyles([
          'accordion',
          'noFocus',
          state.layout.selectedDetailTab === tab.id ? 'active' : '',
          styles.accordionTopic,
        ])}
        title={
          state.layout.selectedDetailTab === tab.id ? 'Fechar' : 'Expandir'
        }
        onClick={() => {
          DataLayer.clickEvent({
            element: tab.title,
            elementCategory: 'botao',
            pageSection: 'conteudo',
            pageSubsection,
          });
          handleSelectedDetailTab(
            state.layout.selectedDetailTab === tab.id ? null : tab
          );
        }}
      >
        <div className="chevron">
          <Image
            src={ui.accordionChevron}
            alt="Ver mais"
            width={14}
            height={8}
          />
        </div>
        <h3>{tab.title}</h3>
      </button>
      <div
        id={`panel-${tab.id}`}
        className={scssStyles(['panel', styles.topicAnswer])}
        style={{
          maxHeight: getMaxHeight(tab.id),
        }}
      >
        <div id={`panel-body-${tab.id}`}>
          <Swiper
            observer
            observeParents
            parallax
            onSwiper={(e) => handleSwiper(e, tab.id)}
            controller={
              state.layout.productDetailsMobileSwiperController[tab.id] != null
                ? {
                    control: state.layout.productDetailsMobileSwiperController[
                      tab.id
                    ] as SwiperCore,
                  }
                : undefined
            }
            onSlideChange={(e) => {
              const tabSelected = productDetails.find((p) => p.id === tab.id);
              handleSwiper(e, tab.id);
              setReference(tabSelected);
              rerender((r) => !r);
            }}
            onTouchStart={(_, event) => {
              const clientX = getClientXFromDifferentEvents(event);
              setTouchLocation(clientX);
            }}
          >
            {tab.children.map((slide) => (
              <SwiperSlide key={`${tab.id}-x-0-${slide.id}`}>
                <Slide slide={{ ...slide, tabId: tab.id }} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={styles.controls}>
            <ArrowButton
              previous
              disabled={
                state.layout.productDetailsMobileSwiperController[tab.id]
                  ?.activeIndex === 0
              }
              className={styles.arrow}
              handleClick={() => {
                DataLayer.clickEvent({
                  element: 'anterior',
                  elementCategory: 'icone',
                  pageSection: pageSubsection,
                  pageSubsection: tab.slug,
                });
                state.layout.productDetailsMobileSwiperController[
                  tab.id
                ]?.slidePrev();
              }}
            />
            <div className={styles.bullets}>
              {!!bulletProps.before &&
                Array.from(Array(bulletProps.before).keys())
                  .slice(0, 2)
                  .map((_, i) => (
                    <button
                      key={`smaller-bullet-ProductDetails-before-${i}`}
                      className={scssStyles([
                        styles.bullet,
                        i == 1 || bulletProps.before === 1
                          ? 'mediumBullet'
                          : 'smallerBullet',
                      ])}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: `foto-${activeIndex - 2}`,
                          elementCategory: 'icone',
                          pageSection: 'conteudo',
                          pageSubsection,
                        });
                        if (
                          state.layout.productDetailsMobileSwiperController &&
                          state.layout.productDetailsMobileSwiperController[
                            tab.id
                          ]
                        ) {
                          state.layout.productDetailsMobileSwiperController[
                            tab.id
                          ]?.slideTo(activeIndex - 2);
                        }
                      }}
                    >
                      <span />
                    </button>
                  ))}
              {tab.children.map((c, index) => {
                if (bulletProps.range.indexOf(index) === -1) return null;
                return (
                  <button
                    key={`bullet-s-j-${c.id}`}
                    title={c.title}
                    className={scssStyles([
                      styles.bullet,
                      state.layout.productDetailsMobileSwiperController[tab.id]
                        ?.activeIndex === index
                        ? styles.active
                        : '',
                    ])}
                    onClick={() => {
                      DataLayer.clickEvent({
                        element: tab.children[index + 1].slug,
                        elementCategory: 'icone',
                        pageSection: 'tudo-sobre',
                        pageSubsection: tab.slug,
                      });
                      if (
                        c.index &&
                        state.layout.productDetailsMobileSwiperController &&
                        state.layout.productDetailsMobileSwiperController[
                          tab.id
                        ]
                      ) {
                        state.layout.productDetailsMobileSwiperController[
                          tab.id
                        ]?.slideTo(index);
                      }
                    }}
                  >
                    <span />
                  </button>
                );
              })}
              {!!bulletProps.after &&
                Array.from(Array(bulletProps.after).keys())
                  .slice(0, 2)
                  .map((_, i) => (
                    <button
                      key={`smaller-bullet-ProductDetails-after-${i}`}
                      className={scssStyles([
                        styles.bullet,
                        i == 0 || bulletProps.after === 1
                          ? 'mediumBullet'
                          : 'smallerBullet',
                      ])}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: `foto-${activeIndex + 2}`,
                          elementCategory: 'icone',
                          pageSection: 'conteudo',
                          pageSubsection,
                        });
                        if (
                          state.layout.productDetailsMobileSwiperController &&
                          state.layout.productDetailsMobileSwiperController[
                            tab.id
                          ]
                        ) {
                          state.layout.productDetailsMobileSwiperController[
                            tab.id
                          ]?.slideTo(activeIndex + 2);
                        }
                      }}
                    >
                      <span />
                    </button>
                  ))}
            </div>
            <ArrowButton
              className={styles.arrow}
              disabled={
                (state.layout.productDetailsMobileSwiperController[tab.id]
                  ?.activeIndex ?? tab.children.length - 1) >=
                tab.children.length - 1
              }
              handleClick={() => {
                DataLayer.clickEvent({
                  element: 'proximo',
                  elementCategory: 'icone',
                  pageSection: pageSubsection,
                  pageSubsection: tab.slug,
                });
                state.layout.productDetailsMobileSwiperController[
                  tab.id
                ]?.slideNext();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const SwipersMobile: FC<ISetReference> = ({ setReference }) => {
  const [, rerender] = useState(false);
  return (
    <div className={styles.container}>
      {productDetails.map((tab) => (
        <div key={`sw-frag-${tab.id}`}>
          <Tab tab={tab} rerender={rerender} setReference={setReference} />
        </div>
      ))}
    </div>
  );
};

export default SwipersMobile;
