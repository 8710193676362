import {Conditional, CTAButton} from "@/components";
import {dataCTASection, dataCTASectionMobile} from "@/data/menu.data";
import DataLayer from "@/utils/DataLayer";
import { FC } from "react";
import styles from "./cta-section.module.scss";

export const CTASection: FC = () => {
  return (
    <div className={styles.ctaSection}>
      <div className={styles.title}>
        <h2>
          Vamos <span>Negociar?</span>
        </h2>
      </div>
      <div className={styles.buttonHolder}>
          <Conditional notOn={'desktop'}>
              {dataCTASectionMobile?.map((item) => {
                  return (
                      <CTAButton
                          key={`menu-cta-${item.id}`}
                          href={item.url}
                          className={styles.ctaButton}
                          text={item.label}
                          title={item.label}
                          iconCode={item.iconCode}
                          handleClick={() =>
                              DataLayer.clickEvent({
                                  element: item.label,
                                  elementCategory: "cta",
                                  pageSection: "footer",
                                  pageSubsection: "ctas",
                              })
                          }
                      />
                  );
              })}
          </Conditional>
          <Conditional notOn={'mobile'}>
              {dataCTASection?.map((item) => {
                  return (
                      <CTAButton
                          key={`menu-cta-${item.id}`}
                          href={item.url}
                          className={styles.ctaButton}
                          text={item.label}
                          title={item.label}
                          iconCode={item.iconCode}
                          handleClick={() =>
                              DataLayer.clickEvent({
                                  element: item.label,
                                  elementCategory: "cta",
                                  pageSection: "footer",
                                  pageSubsection: "ctas",
                              })
                          }
                      />
                  );
              })}
          </Conditional>
      </div>
    </div>
  );
};
